import React from 'react';
import CtaButton from '../../../shared/CtaButton';

export default function UploadButtons({
  onCancel,
  onSubmit,
  cancelButtonRef,
  submitButtonRef,
  ctaButtonContent,
  ctaButtonStyle,
}) {
  return (
    <>
      <button
        className="button media-background__button media-background__button--redo"
        data-testid="redo-button"
        type="button"
        onClick={onCancel}
        ref={cancelButtonRef}
      />
      <CtaButton
        className="button media-background__button"
        data-testid="confirm-upload-button"
        type="submit"
        onClick={onSubmit}
        buttonRef={submitButtonRef}
        ctaButtonContent={ctaButtonContent}
        ctaButtonStyle={ctaButtonStyle}
      />
    </>
  );
}
